import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"
import JoinTheBoxes from "../components/AcademyPageComponent/JoinTheBoxes"
import { JoinTheBoxesPhotosAcad } from "../components/AcademyPageComponent/index"
import { JoinTheBoxesPhotosCompe } from "../components/CompetitionPageComponent/index"

const Welcome = styled.div`
  padding: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .rainbow {
    font-size: 2.8em;
    margin: 0;
    background: linear-gradient(
      106.03deg,
      #49ab64 23.82%,
      #dc7929 42.6%,
      #e72836 56.78%,
      #7723bc 77.64%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  p {
    text-align: center;
    width: 35%;
  }

  .boxes {
    margin-top: -60px;
  }

  .extra-top {
    margin-top: -100px;
  }

  .token-expired {
    text-align: center;
    color: ${(props) => props.theme.colors.red};

    h1,
    h2 {
      margin-bottom: 0;
    }

    a {
      color: rgb(255, 203, 21);
    }
  }

  @media (max-width: 795px) {
    padding-left: 20px;
    padding-right: 20px;

    p {
      width: 70%;
    }

    .boxes,
    .boxes-extra-top {
      margin-left: -20px;
      margin-right: -20px;
    }

    .token-expired {
      h1 {
        font-size: 2rem;
      }

      h2 {
        font-size: 1.25rem;
      }
    }
  }
`

function getUrlParameter(name) {
  if (typeof window === "undefined") return ""
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]")
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)")
  var results = regex.exec(window.location.search)
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "))
}

const WelcomePage = () => (
  <Layout>
    <SEO title="Welcome" />
    <Welcome>
      {getUrlParameter("success") !== "false" ? (
        <>
          <h1 className="rainbow">Welcome DigiFellas!</h1>
          <p>
            Thank you for verifying your account. Now you can proceed with your{" "}
            <span style={{ color: "#FFCB15" }}>
              previous intended registration.
            </span>
          </p>
        </>
      ) : (
        <div className="token-expired">
          <h1>Token has expired!</h1>
          <h2>
            Please resend the verification email through{" "}
            <Link to="/dashboard">the dashboard</Link>
          </h2>
          <h5>*Token expired within one hour</h5>
        </div>
      )}
      <div className="boxes">
        <JoinTheBoxes type="competition" photos={JoinTheBoxesPhotosCompe} />
      </div>
      <div className="boxes extra-top">
        <JoinTheBoxes type="academy" photos={JoinTheBoxesPhotosAcad} />
      </div>
    </Welcome>
  </Layout>
)

export default WelcomePage
